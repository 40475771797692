import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(24px);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0.5rem 1rem;
`;

const LinkItem = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: grey;
  :hover {
    color: white;
  }
`;

const BoardFooter = () => {
  return (
    <Container>
      <LinkItem to="/terms-of-service">Terms of Service</LinkItem>
      <LinkItem to="/privacy-policy">Privacy Policy</LinkItem>
    </Container>
  );
};

export default BoardFooter;
