import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { login } from "../../Services/userService";
import Background from "../../Components/Background";
import {
  BgContainer,
  Container,
  TrelloIconContainer,
  FormSection,
  FormCard,
  Form,
  Title,
  Input,
  Button,
  Icon,
  Hr,
  Link,
  FormFooter,
} from "./Styled";
import { Link as RouterLink } from "react-router-dom";
import logo from "../../Images/logo.svg";
import GoogleLoginButton from "./GoogleLoginButton";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import GoogleIcon from "@mui/icons-material/Google";

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const [userInformations, setUserInformations] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    document.title =
      "Login | OlO Tasks: Free task management for anyone, anywhere and anytime";
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    login(userInformations, dispatch, redirect || "/boards");
  };

  if (localStorage.getItem("token"))
    return <Navigate push to={redirect || "/boards"} />;
  return (
    <GoogleOAuthProvider clientId="891729642437-puhjti84hso7vc3rdasbflpo8uo5o3pm.apps.googleusercontent.com">
      <Container>
        <TrelloIconContainer onClick={() => navigate("/")}>
          <Icon src={logo} />
        </TrelloIconContainer>
        <FormSection>
          <FormCard>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Title>Log in to Trello</Title>
              <Input
                type="email"
                placeholder="Enter email"
                required
                value={userInformations.email}
                onChange={(e) =>
                  setUserInformations({
                    ...userInformations,
                    email: e.target.value,
                  })
                }
              />
              <Input
                type="password"
                placeholder="Enter password"
                required
                value={userInformations.password}
                onChange={(e) =>
                  setUserInformations({
                    ...userInformations,
                    password: e.target.value,
                  })
                }
              />
              <Button>Log in</Button>
              <Title>Or continue with:</Title>
              <GoogleLoginButton />
              <Hr />
              <Link fontSize="0.85rem" onClick={() => navigate("/register")}>
                Sign up for an account
              </Link>
              <Link
                fontSize="0.85rem"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot your password?
              </Link>
            </Form>
            <Hr />
            <FormFooter>
              <RouterLink
                fontSize="0.85rem"
                style={{
                  color: "#6b808c",
                  marginRight: "0.5rem",
                }}
                to={"/terms-of-service"}
              >
                Terms of service
              </RouterLink>
              <RouterLink
                fontSize="0.85rem"
                style={{
                  color: "#6b808c",
                }}
                to={"/privacy-policy"}
              >
                Privacy policy
              </RouterLink>
            </FormFooter>
          </FormCard>
        </FormSection>
      </Container>
    </GoogleOAuthProvider>
  );
};

export default Login;
