import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Typography, Box } from "@mui/material";
import privacyPolicyData from "./privacy-policy.json";
import Navbar from "../../Components/Navbar";
import IndexNav from "../../Components/IndexNav";
import BoardFooter from "../../Components/BoardFooter";

const SectionTitle = styled(Typography)`
  margin-top: 20px;
  font-weight: bold;
`;

const SectionContent = styled(Typography)`
  margin-top: 10px;
  line-height: 1.6;
`;

export const PageContainer = styled.div`
  background: linear-gradient(rgb(234, 230, 255), rgb(255, 255, 255)) no-repeat;
  color: rgb(9, 30, 66);
  width: 100%;
  display: flex;
  justify-content: center;
  color: #091e42;
`;

const PrivacyPolicy = () => {
  const [policyData, setPolicyData] = useState(null);

  useEffect(() => {
    // Simulating fetching data from the JSON file
    setPolicyData(privacyPolicyData);
  }, []);

  if (!policyData) return <div>Loading...</div>;

  return (
    <PageContainer>
      <Container
        maxWidth="md"
        sx={{
          marginTop: "80px",
        }}
      >
        <IndexNav />
        <Typography variant="h3" gutterBottom>
          {policyData.title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {policyData.intro}
        </Typography>

        {policyData.sections.map((section, index) => (
          <Box key={index}>
            <SectionTitle variant="h5">{section.title}</SectionTitle>
            <SectionContent variant="body1">{section.content}</SectionContent>
          </Box>
        ))}

        <Typography variant="body1" gutterBottom sx={{ marginTop: "40px" }}>
          {policyData.conclusion}
        </Typography>
      </Container>
      <BoardFooter />
    </PageContainer>
  );
};

export default PrivacyPolicy;
